<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="项目">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.project_id"
                placeholder="请输入项目"
                :default-active-first-option="false"
                @search="this.projectSearch"
                @change="handleProjectChange"
              >
                <a-select-option v-for="d in projectList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="仓库">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                placeholder="请输入仓库"
                :default-active-first-option="false"
                @search="this.warehouseSearch"
                @change="handleWarehouseChange"
              >
                <a-select-option v-for="d in warehouseList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleArchived">批量存档</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1000 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 300px">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="approval_needed" slot-scope="text">
        <span>{{ text ? '是' : '否' }}</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleAdjustmentDetail(record)" v-if="record.is_adjustment">查看调整</a>
        <template v-if="false">
          <a @click="handleAttachment(record)">附件</a>
          <a-divider type="vertical" />
          <a @click="handleAdjustment(record)">库存调整</a>
          <a-divider type="vertical" />
          <!--          <a @click="handleEdit(record)">编辑</a>-->
          <!--          <a-divider type="vertical" />-->
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <a-drawer
      title="库存调整"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      @close="onClose"
      width="500">
      <a-spin :spinning="drawerLoading" v-if="inventoryAdjustmentObj">
        <div :title="inventoryAdjustmentObj.name">
          <span class="desc_item_drawer">仓库：{{ inventoryAdjustmentObj.warehouse }}</span><br>
          <span class="desc_item_drawer">数量：{{ (inventoryAdjustmentObj.count / 1).toFixed(2) | numberFormat }}</span><br>
          <span class="desc_item_drawer">单位：{{ $Dictionaries.unit[inventoryAdjustmentObj.unit] }}</span><br>
          <span class="desc_item_drawer">预估人民币单价：{{ (inventoryAdjustmentObj.unit_price_cny / 1).toFixed(2) | numberFormat }} </span><br>
          <span class="desc_item_drawer">已开启审批：{{ inventoryAdjustmentObj.is_approval_needed ? '是' : '否' }}</span><br>
          <span class="desc_item_drawer">审批状态：<span :class="statusText[inventoryAdjustmentObj.status < 0 ? 0 : inventoryAdjustmentObj.status][0]">{{ inventoryAdjustmentObj.is_approval_needed ? ($Dictionaries.approval_result[inventoryAdjustmentObj.status] ? $Dictionaries.approval_result[inventoryAdjustmentObj.status] : '无') : '未开启审批' }}</span></span><br>
          <span class="desc_item_drawer">已存档：{{ inventoryAdjustmentObj.is_archived ? '是' : '否' }}</span><br>
          <span class="desc_item_drawer">创建时间：{{ inventoryAdjustmentObj.create_time | dayjs }}</span>
          <div v-if="false">
            <a-collapse :bordered="false">
              <template #expandIcon="props">
                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
              </template>
              <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="inventoryAdjustmentObj.attachment.length > 0">
                <a v-for="(attachment) in inventoryAdjustmentObj.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc_item_drawer"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </a-spin>
    </a-drawer>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <archived-form
      ref="archivedModal"
      :visible="archived_visible"
      @cancel="handleArchivedCancel"
      @ok="handleArchivedOK"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import ArchivedForm from '../modules/ArchivedForm'
import {
  inventory_list,
  inventory_create,
  inventory_delete,
  inventory_archived,
  inventory_update
} from '@/api/trade_inventory'
import debounce from 'lodash/debounce'
import { project_list } from '@/api/trade_project'
import { warehouse_list } from '@/api/trade_warehouse'
import { createProcessResult, getForms } from '@/api/trade_design'
import { inventory_adjustment_list } from '@/api/trade_inventory_adjustment'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ArchivedForm
  },
  data () {
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      approval_again: false,
      loading: false,
      confirmLoading: false,
      drawerLoading: false,
      // 创建窗口控制
      visibleDrawer: false,
      inventoryAdjustmentObj: undefined,
      statusText: {
        0: ['none', '无'],
        1: ['waiting', '待审批'],
        2: ['pass', '通过'],
        3: ['un_pass', '驳回']
      },
      visible: false,
      archived_visible: false,
      mdl: {},
      projectList: [],
      warehouseList: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '项目',
          dataIndex: 'project',
          width: 150,
          ellipsis: true
        },
        {
          title: '仓库',
          dataIndex: 'warehouse',
          width: 150,
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 180,
          ellipsis: true
        },
        {
          title: '单位',
          dataIndex: 'unit',
          ellipsis: true,
          align: 'center',
          width: 100,
          customRender: (text) => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '货币',
          dataIndex: 'currency',
          ellipsis: true,
          width: 120,
          align: 'center',
          customRender: (text) => this.$Dictionaries.currency[text] || '无'
        },
        {
          title: '预估人民币价格',
          dataIndex: 'unit_price_cny',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 180,
          ellipsis: true
        },
        {
          title: '是否已调整',
          dataIndex: 'is_adjustment',
          ellipsis: true,
          align: 'center',
          width: 120,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '开启审批',
          dataIndex: 'is_approval_needed',
          ellipsis: true,
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'approval_needed' }
        },
        {
          title: '已存档',
          dataIndex: 'is_archived',
          ellipsis: true,
          align: 'center',
          width: 120,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '审批状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          customRender: (text, record) => record.is_approval_needed ? this.$Dictionaries.approval_result[text] : '未开启审批'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return inventory_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: record.is_archived
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.handleProjectSearch(undefined)
    this.handleWarehouseSearch(undefined)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: record.is_archived
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleArchived () {
      if (this.selectedRowKeys.length > 0) {
        this.archived_visible = true
      } else {
        this.$message.info('选择存档记录')
      }
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleArchivedCancel () {
      this.archived_visible = false
      const form = this.$refs.archivedModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAttachment (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/inventory/' + record.id + '/attachment', query: { project: record.project, warehouse: record.warehouse } })
    },
    handleAdjustment (record) {
      this.$router.push({ path: '/trade/inventory/' + record.id + '/adjustment', query: { project: record.project, warehouse: record.warehouse } })
    },
    handleAdjustmentDetail (record) {
      this.inventoryAdjustmentObj = undefined
      this.visibleDrawer = true
      this.drawerLoading = true
      inventory_adjustment_list({ inventory_id: record.id }).then(res => {
        if (res.code === 1000) {
          if (res.data.entries && res.data.entries.length > 0) {
            this.inventoryAdjustmentObj = res.data.entries[0]
          }
        }
      }).finally(() => {
        this.drawerLoading = false
      })
    },
    onClose () {
      this.visibleDrawer = false
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
      // this.$router.push({ path: '/trade/bank/' + record.id })
    },
    approvalNeeded (record) {
      inventory_update({ is_approval_needed: !record.is_approval_needed }, record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
          if (!record.is_approval_needed) {
            this.geneApprovalInfo(res.data.id)
          }
        }
      })
    },
    handleDelete (record) {
      inventory_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          inventory_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
              if (values.is_approval_needed) {
                this.geneApprovalInfo(res.data.id)
              }
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      }
    },
    handleWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.warehouseList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleWarehouseChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleWarehouseSearch(value)
      }
    },
    handleArchivedOK () {
      const form = this.$refs.archivedModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const data = {
            ids: this.selectedRowKeys,
            ...values
          }
          inventory_archived(data).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.archived_visible = false
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    geneApprovalInfo (id) {
      this.createContractApproval(id, 'trade_inventory')
    },
    createContractApproval (id, type) {
      getForms({ is_stop: 0, template_type: type, data_id: id }).then(res => {
        console.log(res)
        const shenpi_list = res.data.entries
        if (shenpi_list && shenpi_list.length > 0) {
          for (let i = 0, len = shenpi_list.length; i < len; i++) {
            const item = shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === type) {
                    this.createProcessNotice(item.id, id, type)
                    break
                  }
                }
              }
            }
          }
        } else {
          this.$message.warning('先创建审批模板')
          // this.createProcessNotice(-1, ids, type)
        }
      })
    },
    createProcessNotice (template_id, ids, type) {
      const data = {
        'data_ids': [ids],
        'template_id': template_id,
        'template_type': type,
        'approval_again': this.approval_again
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          console.log('创建成功')
          this.$refs.table.refresh()
        }
      })
    }

  }
}
</script>
<style scoped>
.desc_item_drawer {
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
}
</style>
