import request from '@/utils/request'

const inventoryApi = {
  trade_inventory_list: '/trade/inventory/',
  trade_inventory_create: '/trade/inventory/',
  trade_inventory_update: '/trade/inventory/',
  trade_inventory_delete: '/trade/inventory/',
  trade_inventory_archived: '/trade/inventory_archived/'
}

/**
 * 列表
 */
export function inventory_list (parameter) {
  return request({
    url: inventoryApi.trade_inventory_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function inventory_create (parameter) {
  return request({
    url: inventoryApi.trade_inventory_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function inventory_update (parameter, corporation_id) {
  return request({
    url: inventoryApi.trade_inventory_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function inventory_delete (corporation_id) {
  return request({
    url: inventoryApi.trade_inventory_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function inventory_archived (parameter) {
  return request({
    url: inventoryApi.trade_inventory_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
